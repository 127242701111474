@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #products {
    width: 100%;
  }
}

#scroll {
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: #3498db;
  text-indent: -9999px;
  display: none;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
}
#scroll span {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -8px;
  margin-top: -12px;
  height: 0;
  width: 0;
  border: 8px solid transparent;
  border-bottom-color: #ffffff;
}
#scroll:hover {
  background-color: #e74c3c;
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}

.alert-success-msg{
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
  text-align: center;
}

.alert-success-msg span{
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-right: 7px;
}
